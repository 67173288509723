import React from "react";
import LeftSidebar from "./../left_sidebar";
import Footer from "./../footer";
import Topbar from "./../topbar";
import FetchAllApi from "../../api_links/fetch_all_api";
import config from "../../api_links/api_links";
import jQuery from "jquery";
// import ReactDataSheet from 'react-datasheet';
// import 'react-datasheet/lib/react-datasheet.css';
import moment from "moment";
import Comma from "../comma";
var _ = require('lodash');

class ClientDataReview extends React.Component {
    constructor(props) {
        super(props);
        //const { history } = this.props;
        this.state = {
            logged_user_id: localStorage.getItem("logged_user_id"),
            logged_client_id: localStorage.getItem("logged_client_id"),
            logged_role_id: localStorage.getItem("logged_role_id"),
            logged_user_name: localStorage.getItem("logged_user_name"),
            logged_user_email: localStorage.getItem("logged_user_email"),
            logged_user_phone: localStorage.getItem("logged_user_phone"),
            logged_user_image: localStorage.getItem("logged_user_image"),
            logged_company_name: localStorage.getItem("logged_company_name"),
            date_format: localStorage.getItem("date_format")?localStorage.getItem("date_format"):"YYYY-MM-DD",

            role_permissions:
                JSON.parse(localStorage.getItem("role_permissions")) || [],

            customer_and_job_list: [],
            customer_and_job_lists: [],
            customer_credit_list:[],
            vendor_list:[],
            customer_invoice_list: [],
            customer_invoicelist:[],
            selected_customer_credit_index:-1,
            SubAccountList:[],
            is_auto_apply:false,
            page_select:"Customers",
            vendor_credit_list:[],
            selected_vendor_credit_index:-1,
            vendor_bill_list:[],
            vendor_id:'',
            account_v:'',
            account:'',
            currencies:config.all_currency_list,
            currency_clone:config.all_currency_list,
            CurrentSelectedCurr: "",
            transition_date: ''
        };
    }


    rename = (obj, curr) => {
        let a = {}
        Object.keys(obj).map((key) => {
            let newKey = key.replace(curr, '')
            Object.assign(a, { [newKey]: obj[key] })
        })
        return a
    }

    set_default_currency = (subList) => {
        var defaultCurrency = localStorage.getItem("default_currency");
        //   this.findInSubAccountList(defaultCurrency);
        if(defaultCurrency){
            this.setState(
                {
                    CurrentSelectedCurr: defaultCurrency
                },()=>{
                    var currency = this.state.CurrentSelectedCurr;
                    var result = [];

                    subList.forEach((item, i) => {
                        var kk = "Accounts Receivable" + "-" + currency;
                        if (item.name === kk) {
                            result.push(item);
                        }
                      
                    });

                    // newly added to check custom receivable accounts
                    if(result.length == 0) {
                        let custom_ar_account = _.filter(this.state.SubAccountList,{currency:currency});
                        if(custom_ar_account && custom_ar_account.length > 0) {
                        result.push(custom_ar_account[0]);
                        jQuery("#account_id").val(result[0].id);
                
                        this.setState({
                            account: result[0].id
                        });
                        }
                    }
                    // newly added to check custom receivable accounts - end
            
                    if (result.length === 0) {
                        var coreData = {
                            account_name: "Accounts Receivable" + "-" + currency,
                            account_type_id: 2,
                            currency: currency,
                            client_id: this.state.logged_client_id,
                        };
                        
                        FetchAllApi.addNewAccountName(coreData, (err, response) => {
                            if (response.status === 1) {
                                this.getSubAccountList(response.account_id);
                                jQuery("#account_id").val(response.account_id);
                                this.setState({ account: response.account_id })
                            } else {
                            }
                        });
                    }else {
                        if (
                            jQuery("#invoice_curr_id option:selected").val() != "" &&
                            jQuery("#invoice_curr_id option:selected").val() != undefined
                        ) {
                            jQuery("#account_id").val(result[0].id);
                            this.setState({ account: result[0].id })
                        }
                    }
                    
                    var resultPayable = [];
                    subList.forEach((item, i) => {
                        var kkPayable = "Accounts Payable" + "-" + currency;
                        if (item.name == kkPayable) {
                            resultPayable.push(item);
                        }
                    });
                    if (resultPayable.length === 0) {
                       
                        var coreDataPayable = {
                            account_name: "Accounts Payable" + "-" + currency,
                            account_type_id: 5,
                            currency: currency,
                            client_id: this.state.logged_client_id,
                        };
                      
                        FetchAllApi.addNewAccountName(coreDataPayable, (err, response) => {
                            if (response.status === 1) {
                                this.getSubAccountList(response.account_id);
                                jQuery("#account_id_v").val(response.account_id);
                                this.setState({ account_v: response.account_id })
                            } else {
                            }
                        });
                    } else {
                        
                        if (
                            jQuery("#invoice_curr_id_v option:selected").val() != "" &&
                            jQuery("#invoice_curr_id_v option:selected").val() != undefined
                        ) {
                            jQuery("#account_id_v").val(resultPayable[0].id);
                            this.setState({ account_v: resultPayable[0].id })
                        }
                    }
                    
                })
            
        }
    }


    getSubAccountList = () => {
        var coreData = {
            account_type_id: 2,
            client_id: this.state.logged_client_id,
        };

        if(this.state.page_select == "Vendors") {
            coreData = {
                account_type_id: 5,
                client_id: this.state.logged_client_id,
            };
        }

        FetchAllApi.getSubAccountList(coreData, (err, response) => {
            if (response.status === 1) {
                this.setState({ SubAccountList: response.list });
                this.set_default_currency(response.list)
            } else {
            }
        });
    };


    findInSubAccountList = (currency) => {
        this.setState(
            {
                CurrentSelectedCurr: currency
            })
        var currency = currency;
        var result = [];
        this.state.SubAccountList.forEach((item, i) => {
            var fullString = item.name.split("-");
            var list_curr = fullString[1];

            var kk = "Accounts Receivable" + "-" + currency;
            if (item.name == kk) {
                result.push(item);
            }
        });

    // newly added to check custom receivable accounts
    if(result.length == 0) {
        let custom_ar_account = _.filter(this.state.SubAccountList,{currency:currency});
        if(custom_ar_account && custom_ar_account.length > 0) {
          result.push(custom_ar_account[0]);
          jQuery("#account_id").val(result[0].id);
  
          this.setState({
            account: result[0].id
          });
        }
      }
      // newly added to check custom receivable accounts - end


        if (result.length === 0) {
            var coreData = {
                account_name: "Accounts Receivable" + "-" + currency,
                account_type_id: 2,
                currency: currency,
                client_id: this.state.logged_client_id,
            };

            FetchAllApi.addNewAccountName(coreData, (err, response) => {
                if (response.status === 1) {
                    this.getSubAccountList(response.account_id);
                    jQuery("#account_id").val(response.account_id);
                    this.setState({ account: response.account_id })
                } else {
                }
            });
        } else {
            if (
                jQuery("#invoice_curr_id option:selected").val() != "" &&
                jQuery("#invoice_curr_id option:selected").val() != undefined
            ) {
                jQuery("#account_id").val(result[0].id);
                this.setState({ account: result[0].id })
            }
        }
    };

    findInSubAccountList_v = (currency) => {
        this.setState(
            {
                CurrentSelectedCurr: currency
            })
        var result = [];
        this.state.SubAccountList.forEach((item, i) => {
            var kk = "Accounts Payable" + "-" + currency;
           
            if (item.name == kk) {
                result.push(item);
            }
        });

         // newly added to check custom payable accounts
        if(result.length == 0) {
            let custom_ap_account = _.filter(this.state.SubAccountList,{currency:currency});
            if(custom_ap_account && custom_ap_account.length > 0) {
            result.push(custom_ap_account[0]);
            jQuery("#account_id_v").val(result[0].id);
    
            this.setState({
                account_v: result[0].id
            });
            }
        }
      // newly added to check custom payable accounts - end

        if (result.length === 0) {
            var coreData = {
                account_name: "Accounts Payable" + "-" + currency,
                account_type_id: 5,
                currency: currency,
                client_id: this.state.logged_client_id,
            };

            FetchAllApi.addNewAccountName(coreData, (err, response) => {
                if (response.status === 1) {
                    this.getSubAccountList(response.account_id);
                    jQuery("#account_id_v").val(response.account_id);
                    this.setState({ account_v: response.account_id })
                } else {
                }
            });
        } else {
            if (
                jQuery("#invoice_curr_id_v option:selected").val() != "" &&
                jQuery("#invoice_curr_id_v option:selected").val() != undefined
            ) {
                jQuery("#account_id_v").val(result[0].id);
                this.setState({ account_v: result[0].id })
            }
        }
    };


    get_currencies = () => {
        // commented on 2022-07-13
        // fetch(
        //     `https://api.currencylayer.com/live?access_key=${config.api_key}&source=SGD`
        // )
        //     .then((response) => response.json())
        //     .then((data) => {
        //         let newObj = this.rename(data.quotes, 'SGD')

        //         const currencyAr = [];
        //         let first = newObj;
        //         for (const key in first) {
        //             currencyAr.push(key);
        //         }
        //         this.setState({ currencies: currencyAr, currency_clone: currencyAr });
        //     });
    };

    customer_invoicelist = () => {
        // var client_id = localStorage.getItem("logged_client_id");
        var client_id = this.state.logged_client_id;
        var customer_id = this.state.customer_id;
        var job_id = jQuery("#variable_pay_type_job option:selected").data(
            "status"
        );
        // var invoice_account = jQuery("#ar_account").val()
        var invoice_account = jQuery("#account_id").val()

        if(customer_id && job_id) {
        FetchAllApi.customer_invoicelist(
            client_id,
            customer_id,
            job_id,
            invoice_account,'',
            (err, response) => {
                // console.log("vendor_names", response);

                if (response.status === 1) {
                    this.setState({ customer_invoicelist: response.list });
                    // this.payDue();
                } else {
                    this.setState({ customer_invoicelist: [] });
                    // this.total_paid(0, 0)
                    // this.payDue();
                }
            }
        );
        } else {
            console.log("customer_id or job_id need to load customer invoice list");
        }
    };


    customer_credit_list = () => {

        var client_id = this.state.logged_client_id;
        var customer_id = this.state.customer_id;
        var job_id = jQuery("#variable_pay_type_job option:selected").data(
            "status"
        );
        var invoice_account = jQuery("#account_id").val()


        let data = {
            client_id: client_id,
            customer_id: customer_id,
            job_id: job_id,
            credit_account: invoice_account,
            multi_payment_applied_invoices:''
        }      
        
    if(customer_id && job_id) {
        FetchAllApi.customer_creditlists(client_id, customer_id, job_id, invoice_account,'',1,
            (err, response) => {
                // console.log("vendor_names", response);

                if (response.status === 1) {
                    this.setState({ customer_credit_list: response.response });
                } else {
                    this.setState({ customer_credit_list: [] });
                }
            }
        );
    } else {
        console.log("customer_id or job_id need to load customer credit list");
    }

    };

    vendor_bill_list = () => {
        let client_id = this.state.logged_client_id;
        let vendor_id = this.state.vendor_id;
        let selected_account_id = this.state.account_v;

        FetchAllApi.vendor_invoicelist(
            client_id, vendor_id, selected_account_id, '',
            (err, response) => {
                if (response.status === 1) {
                    this.setState({ vendor_bill_list: response.list });
                } else {
                    this.setState({ vendor_bill_list: [] });
                }
            }
        );
    };

    vendor_credit_list = () => {
        let vendor_id = this.state.vendor_id;
        let account_id = this.state.account_v;

        FetchAllApi.vendor_credit_list(
            vendor_id, account_id, '',1,
            (err, response) => {
                if (response.status === 1) {
                    this.setState({ vendor_credit_list: response.response },()=>{
                        console.log("Intial vendor credit list",this.state.vendor_credit_list);
                    });
                } else {
                    this.setState({ vendor_credit_list: [] });
                }
            }
        );
    };

    handleChanges = () => {
        let variable_pay_type_job = jQuery(
            "#variable_pay_type_job option:selected"
        ).data("status");

         this.customer_invoicelist();
         this.customer_credit_list();

    };

    handleChanges_v = () => {
        this.vendor_bill_list();
        this.vendor_credit_list();
    };

    handleChange = (e) => {
        // alert(e)
        // let customer_id = jQuery("#customer_id option:selected").data(
        //     "status"
        // );

         let selectedIndex = e.target.options.selectedIndex;
         let customer_id = e.target.options[selectedIndex].getAttribute('data-status');
         let v_name = e.target.value;
         
         setTimeout(()=>{
            this.setState({
                selectValue: customer_id,
                // vendorName: e.target.value,
                vendorName:v_name,
                customer_and_job_lists: [],
                customer_invoicelist:[],
                customer_credit_list:[]
            });
            
            this.customer_and_job_lists(customer_id);
            this.customer_invoicelist();
            this.customer_credit_list();
         },500)
        
    };

    handleChange_v = (e) => {
       
         let selectedIndex = e.target.options.selectedIndex;
         let vendor_id = e.target.options[selectedIndex].getAttribute('data-status');
        this.setState({
            vendor_id: vendor_id,
            vendorName: e.target.value
        },()=>{
            this.vendor_bill_list();
            this.vendor_credit_list();
        });
    };

    customer_and_job_list = () => {
        // var client_id = localStorage.getItem("logged_client_id") ;
        var client_id = this.state.logged_client_id;
        var from_customer_receive_payment = 1;
        let filter_id=2
        FetchAllApi.customer_and_job_list(
            client_id,
            from_customer_receive_payment,filter_id,
            (err, response) => {
                // console.log("vendor_names", response);

                if (response.status === 1) {
                    this.setState({ customer_and_job_list: response.list });
                } else {
                }
            }
        );
    };

    vendorList = () => {
        var client_id = this.state.logged_client_id;
        let filter_id = 2;
        FetchAllApi.getVendorNames(
            client_id,
            filter_id,
            (err, response) => {
                if (response.status === 1) {
                    this.setState({ vendor_list: response.list });
                } else {
                    this.setState({ vendor_list:[] });
                }
            }
        );
    };

    customer_and_job_lists = (customer_id) => {
        // var client_id = localStorage.getItem("logged_client_id") ;
        var client_id = this.state.logged_client_id;
        var customer_id = customer_id;
        var from_customer_receive_payment = 1;
        let filter_id=2
        FetchAllApi.customer_and_job_lists(
            client_id,
            customer_id,
            from_customer_receive_payment,filter_id,
            (err, response) => {
                // console.log("vendor_names", response);

                if (response.status === 1) {
                    this.setState({ customer_and_job_lists: response.list });
                    this.setState({ customer_id: customer_id }, this.handleChanges());
                } else {
                    this.setState({ customer_and_job_lists: [] }, this.handleChanges());
                }
            }
        );
    };


    componentDidUpdate() {
        window.jQuery(".selectpicker").selectpicker("refresh");
        // jQuery("#currency_selected").val(4);
   
    }

    UNSAFE_componentWillMount() {
        jQuery(document.body).removeClass("minimize_leftbar");
        //console.log("logged_user_id", this.state.logged_user_id);

        jQuery("title").html("Customer | GBSC");

        if (
            this.state.logged_user_id === "" ||
            this.state.logged_user_id === "null" ||
            this.state.logged_user_id === "undefined"
        ) {
            this.props.history.push("/");
        }
    }

    routedChange(parameter) {
        this.props.history.push("/" + parameter);
        window.scrollTo(0, 0);
    }

    componentDidMount() {
        this.getSubAccountList();
        this.customer_and_job_list();
        this.get_currencies();
        this.vendorList();      
        // document.getElementById("sticky-tb-hdr").addEventListener("scroll", function () {
        //   var translate = "translate(0," + this.scrollTop + "px)";
        //   if (this.querySelector("thead") != null && this.querySelector("thead") != undefined && this.querySelector("thead").style != null) {
        //     this.querySelector("thead").style.transform = translate;
        //   }
        // });

    

       

        jQuery(window).on("load", function () {
            window.jQuery(".mscroll-y").mCustomScrollbar({
                axis: "y",
                scrollEasing: "linear",
                scrollInertia: 600,
                autoHideScrollbar: "true",
                autoExpandScrollbar: "true",
            });

            window.jQuery(".ib-scroll").mCustomScrollbar({
                scrollEasing: "linear",
                scrollInertia: 600,
                scrollbarPosition: "outside",
            });
        });

        jQuery(document).ready(function () {
            jQuery(".left-navmenu .has-sub").click(function () {
                jQuery(".left-navmenu li a").removeClass("active");
                jQuery(this).addClass("active");
                jQuery(".left-navmenu li a:not(.active)")
                    .siblings(".sub-menu")
                    .slideUp();
                jQuery(this).siblings(".sub-menu").slideToggle();
            });
            jQuery(".left-navmenu .sub-menu li a").click(function () {
                jQuery(".left-navmenu .sub-menu li a").removeClass("active");
                jQuery(this).addClass("active");
            });
            jQuery(".search-btn").click(function () {
                jQuery(".hdr-search").addClass("active");
            });
            jQuery(".hdr-search .close-icon").click(function () {
                jQuery(".hdr-search").removeClass("active");
            });
            window.jQuery(".select-picker").selectpicker();
            jQuery(".label-enclose .label").click(function () {
                jQuery(this).toggleClass("active");
            });
            jQuery(".nav-brand-res").click(function () {
                jQuery(".left-navbar").addClass("active");
            });
            jQuery(".menu-close").click(function () {
                jQuery(".left-navbar").removeClass("active");
            });
        });

       
    }

    toggleFunc = (val) => {
        this.setState({ page_select: val },()=>{
            if(this.state.page_select == "Vendors") {
                this.getSubAccountList();
            } else {
                this.getSubAccountList();
            }
        });
      };

    clearAll=()=>{
        jQuery("#variable_pay_type_job").val([]);
        jQuery("#invoice_curr_id").val([]);
        jQuery("#invoice_curr_id_v").val([]);
        this.setState({
            selectValue:'',
            vendor_id:'',
            vendorName:'',
            customer_credit_list:[],
            customer_invoicelist:[],
            vendor_bill_list:[],
            vendor_credit_list:[],
            account:'',
            account_v:'',
            selected_customer_credit_index:-1,
            selected_vendor_credit_index:-1,
            CurrentSelectedCurr:''
        });
    }

    saveTransactions=()=>{
      if(this.state.page_select == "Customers") {
        var job_id = jQuery("#variable_pay_type_job option:selected").data(
            "status"
        );

          let inputData = {
            client_id: this.state.logged_client_id,
            customer_id:this.state.customer_id,
            job_id:job_id,
            account:this.state.account,
            credit_list: this.state.customer_credit_list
            }

            FetchAllApi.saveCustomerReviewedTransactions(inputData, (err, response) => {
                if (response.status === 1) {
                    alert(response.message);
                    this.setState({
                        customer_invoicelist:[],
                        customer_credit_list:[],
                        is_auto_apply:false //2022-12-26
                    },()=>{
                        this.customer_invoicelist();
                        this.customer_credit_list();
                    });
                   
                } else {
                    alert(response.message);
                }
            });

      } else if(this.state.page_select == "Vendors") {

        let inputData = {
            client_id: this.state.logged_client_id,
            vendor_id:this.state.vendor_id,
            account:this.state.account_v,
            credit_list: this.state.vendor_credit_list
            }

       FetchAllApi.saveVendorReviewedTransactions(inputData, (err, response) => {
        if (response.status === 1) {
            alert(response.message);
            this.setState({
                vendor_credit_list:[],
                vendor_bill_list:[]
            },()=>{
                //2022-12-26
                this.setState({is_auto_apply:false});

                this.vendor_credit_list();
                this.vendor_bill_list();
            });
           
        } else {
            alert(response.message);
        }
    });

    }
}


    logoutLink() {
        localStorage.clear();

        this.props.history.push("/");
    }

    pageLink(page_slug) {
        this.props.history.push("/" + page_slug);
    }

    render() {
        return (
            <div>
                {/* Main Wrapper Starts here */}
                <div className="container-fluid">
                    <div className="row">
                        {/* left-navbar Starts here */}
                        <LeftSidebar history={this.props.history} />
                        {/* left-navbar Ends here */}
                        {/* MainContent Wrapper Starts here */}
                        <div className="main-wrap col-md-12 col-xs-12 pad-r-no">
                            {/* Top bar Starts here */}
                            <div className="top-bar col-md-12 col-xs-12 pad-r-no">
                <form className="hdr-search">
                  <input
                    type="text"
                    className="form-control"
                    name="search"
                    placeholder="Search..."
                  />
                  {/* <button type="submit" class="btn btn-green">Search</button> */}
                  <a href="javascript:;" className="close-icon">
                    <img src="images/close-icon-red.svg" alt="Close" />
                  </a>
                </form>
                <div className="nav-brand-res visible-xs">
                  <img
                    className="img-responsive"
                    src="images/logo-icon.png"
                    alt="LogoIcon"
                  />
                </div>
                <a href="javascript:;" className="back hidden-xs">
                  <img src="images/back-arrow-blue.svg" onClick={() => this.props.history.goBack()} />
                </a>
                {/* <span class="page-title hidden-xs">Preference</span> */}
                <ul className="list-unstyled breadcrumb page-title hidden-xs">
                  <li>
                    <a href="javascript:;">Accountant</a>
                  </li>
                  <li>Client Data Review</li>
                </ul>
                <Topbar history={this.props.history} logoutSubmit={(e) => this.logoutLink()} />
              </div>
                            {/* Top bar Ends here */}
                            <div className="col-md-12 col-xs-12 mar-top visible-xs">
                                <a href="javascript:;" className="back">
                                    <img src="images/back-arrow-blue.svg" />
                                </a>
                                <span className="page-title">Accountant</span>
                            </div>
                            {/* content-top Starts here */}
                            <div className="content-top col-md-12 col-xs-12 bg-trans">
                                <h4>Client Data Review</h4>
                                <div className="row">
                                    <div className="col-md-12 col-xs-12">
                                        <ul className="nav nav-pills transparent nowrap mar-top">
                                            <li className="active"><a data-toggle="pill" href="#cus-data-review" onClick={() => { this.toggleFunc("Customers") }}>Customers</a></li>
                                            <li><a data-toggle="pill" href="#ven-data-review" onClick={() => { this.toggleFunc("Vendors") }}>Vendors</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* content-top Starts here */}
                            {/* Main Content Starts here */}
                            <div className="main-content col-md-12 col-xs-12 pad-t-no">
                                <div className="content-sec tab-content col-md-12 col-xs-12 pad-no mar-t-no">
                                    <div id="cus-data-review" className="col-md-12 col-xs-12 tab-pane fade pad-no active in">
                                        <div className="row">
                                            <div className="content-top col-md-12 col-xs-12">
                                                <form className="custom-form row">
                                                    <div className="form-group col-md-3">
                                                        <label>Currency</label>
                                                        <select
                                                            className="selectpicker form-control hh w-100 "
                                                            id="invoice_curr_id"
                                                            data-live-search="true"
                                                            title={`Choose`}
                                                            value={this.state.CurrentSelectedCurr}
                                                            // data-width="150%"
                                                            onChange={(e) => {
                                                                this.findInSubAccountList(e.target.value);
                                                            }}
                                                        >
                                                            {this.state.currencies &&
                                                                this.state.currencies.map((item) => {

                                                                    if (item.code !== "ALL") {
                                                                        return <option value={item}> {item}</option>;
                                                                    }
                                                                })}
                                                        </select>

                                                    </div>
                                                    {/* <div className="form-group col-md-3 no-edit"> */}
                                                    <div className="form-group col-md-3">
                                                        <label>Accounts Receivable</label>

                                                        <select
                                                            className="selectpicker form-control add-new "
                                                            id="account_id"
                                                            data-live-search="true"
                                                            title={`Choose`}
                                                            data-width="100%"
                                                            onChange={(e) => { 
                                                                let value = e.target.value;
                                                                jQuery("#account_id").val(value);
                                                                this.setState({
                                                                  account: value
                                                                },()=>{
                                                                  this.handleChanges();
                                                                });

                                                            }}
                                                            // disabled={true}
                                                            value={this.state.account}
                                                        >
                                                            {this.state.SubAccountList != undefined &&
                                                                this.state.SubAccountList.map((item, index) => {
                                                                    // console.log("ggggg", this.state.response);
                                                                    if((item.currency != '' && item.currency == this.state.CurrentSelectedCurr) && item.name != 'Accounts Receivable') {
                                                                    return (
                                                                        <option value={item.id}>{item.name}</option>
                                                                    );
                                                                } else {
                                                                    return '';
                                                                }
                                                                })}
                                                        </select>


                                                    </div>

                                                    <div className="form-group col-md-3">
                                                        <label>Customer Name</label>

                                                        <select
                                                            className="selectpicker form-control add-new"
                                                            data-live-search="true"
                                                            title="Choose customer"
                                                            // id="variable_pay_type"
                                                            id="customer_id"
                                                            value={this.state.vendorName}
                                                            // onChange={e => {
                                                            //   if (e.target.event != '') {
                                                            //     this.setSt
                                                            //   } else {
                                                            //     this.setState({ iscustomer_name: true })
                                                            //   }
                                                            // }}

                                                            onChange={(e) => {
                                                                this.handleChange(e);
                                                            }}

                                                      
                                                        >
                                                            <option>choose</option>
                                                            {this.state.customer_and_job_list &&
                                                                this.state.customer_and_job_list.map((item) => {
                                                                    return (
                                                                        <option
                                                                            value={item.name}
                                                                            data-status={item.id}
                                                                            disabled={item.status == 2?true:false}
                                                                            style={{display:item.status == 2?'none':'block'}}
                                                                        >
                                                                            {item.name}
                                                                        </option>
                                                                    );
                                                                })}
                                                        </select>
                                                    </div>
                                                    <div className="form-group col-md-3">
                                                        <label>Job List</label>
                                                        {
                                                            <select
                                                                className="selectpicker form-control add-new"
                                                                data-live-search="true"
                                                                title="Choose a job"
                                                                id="variable_pay_type_job"
                                                                onChange={() => {
                                                                    this.handleChanges();
                                                                }}
                                                            >
                                                                <option>Choose</option>
                                                                {this.state.customer_and_job_lists &&
                                                                    this.state.customer_and_job_lists.map(
                                                                        (item) => {
                                                                            return (
                                                                                <option
                                                                                    value={item.id}
                                                                                    data-status={item.id}
                                                                                >
                                                                                    {item.name}
                                                                                </option>
                                                                            );
                                                                        }
                                                                    )}
                                                            </select>
                                                        }
                                                    </div>

                                                </form>
                                            </div>
                                            <div className="col-md-6 col-xs-12">
                                                <p className="ct-data-title"><span>Step 1:</span> Select Payments / Credits</p>
                                                <div className="report-table reconcile-table ctd-review col-md-12 col-xs-12 pad-no mar-t-no mar-btm">
                                                    <div className="table-responsive hide-v-scroll">
                                                        <table className="table detail-report minw-unset td-vm">
                                                            <thead>
                                                                <tr>
                                                                    <th>
                                                                        {/* <label className="custom-checkbox small">
                                                                            <input type="checkbox" name="all" />&nbsp;
                                                                            <span className="checkmark" />
                                                                        </label> */}
                                                                    </th>
                                                                    <th>Type</th>
                                                                    <th>Credit/Payment No</th>
                                                                    <th>Date</th>
                                                                    <th className="text-right">Total Amount</th>
                                                                    <th className="text-right">Credits Available</th>
                                                                    <th className="text-right">Credits to Apply</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.customer_credit_list &&
                                                                    this.state.customer_credit_list.map((item, i) => {
                                                                        return (
                                                                            <tr>
                                                                                <td>
                                                                                    <label className="custom-checkbox small mar-t-no">
                                                                                        <input type="checkbox" name="all"
                                                                                            checked={this.state.customer_credit_list[i].checked}
                                                                                            disabled={this.state.is_auto_apply}

                                                                                            onChange={(e) => {
                                                                                                let array = this.state.customer_credit_list
                                                                                                if (e.target.checked) {
                                                                                                    array[i] = { ...array[i], checked: true,used_amount: item.available_credits,balance_after_used_amount:item.available_credits }
                                                                                                    this.setState({ customer_credit_list: array,selected_customer_credit_index:i })
                                                                                                } else {
                                                                                                    array[i] = { ...array[i], checked: false, used_amount: '',balance_after_used_amount:0 }
                                                                                                    this.setState({ customer_credit_list: array,selected_customer_credit_index: -1 });

                                                                                                    let invoice_array = this.state.customer_invoicelist;
                                                                                                    if(array[i]) {
                                                                                                        invoice_array.forEach((arr,ind)=>{
                                                                                                        let applied_invoices = array[i]["applied_invoices"] ? array[i]["applied_invoices"]:[];
                                                                                                        let idx = _.findIndex(applied_invoices, function(o) { return o.invoice_id == arr.id; });
                                                                                                        if(idx >= 0) {
                                                                                                            let amt = arr.applied_amount ? Number(arr.applied_amount):0;
                                                                                                            let applied_amount = arr.applied_amount - amt;
                                                                                                            if(applied_amount == 0){
                                                                                                                applied_amount = '';
                                                                                                            }
                                                                                                            let open_balance_after_apply = arr.open_balance_foreign_currency + amt;
                                                                                                            // open_balance_foreign_currency: open_balance_after_apply,
                                                                                                            invoice_array[ind] = { ...invoice_array[ind],checked:false,applied_amount:applied_amount};
                                                                                                        }
                                                                                                    });
                                                                                                    this.setState({ customer_invoicelist: invoice_array },()=>{
                                                                                                        array[i] = { ...array[i], applied_invoices:[] }
                                                                                                        this.setState({ customer_credit_list: array});
                                                                                                    });
                                                                                                    }
                                                                                           
                                                                                                }
                                                                                            }}

                                                                                        />&nbsp;
                                                                                <span className="checkmark" />
                                                                                    </label>
                                                                                </td>
                                                                                {/* <td>{item.credit_memo_type == 1? 'Credit':item.credit_memo_type == 2?'Payment':item.credit_memo_type == 10?'Deposit':'Cheque'}</td> */}
                                                                                <td>{item.credit_type_as_text}</td>
                                                                                <td>{item.credit_number}</td>
                                                                                {/* <td>{moment(item.credit_date).format(
                                                                                        this.state.date_format
                                                                                        )}</td> */}
                                                                                        <td>{item.credit_date}</td>
                                                                                <td className="text-right"><Comma value={item.amount }/></td>
                                                                                <td className="text-right"><Comma value={item.available_credits}/></td>
                                                                                <td><input type="text" className="form-control  text-right" name
                                                                                    value={this.state.customer_credit_list[i].used_amount}
                                                                                    disabled={this.state.customer_credit_list[i].applied_invoices && this.state.customer_credit_list[i].applied_invoices.length > 0}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.value <= item.available_credits) {
                                                                                            let array = this.state.customer_credit_list
                                                                                            array[i] = { ...array[i], used_amount: e.target.value }
                                                                                            if (e.target.value !== '') {
                                                                                                array[i] = { ...array[i], checked: true, balance_after_used_amount:e.target.value }
                                                                                            } else {
                                                                                                array[i] = { ...array[i], checked: false, balance_after_used_amount:0 }
                                                                                            }
                                                                                            //modified on 04-07-2022
                                                                                           // this.setState({ customer_credit_list: array },()=>{
                                                                                            this.setState({ customer_credit_list: array,selected_customer_credit_index:i },()=>{
                                                                                                console.log("customer_credit_list...651",this.state.customer_credit_list);
                                                                                            })
                                                                                        } else {
                                                                                            alert('please use less than or equal avilable available credit')
                                                                                        }

                                                                                    }}
                                                                                /></td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 col-xs-12 text-center mar-btm pad-btm">
                                                    <button type='button' className="btn btn-blue mar-rgt-5"
                                                        onClick={() => {
                                                            this.setState({is_auto_apply:true});
                                                            // let total_credit = 0;
                                                            // let total_invoice = 0;

                                                            // this.state.customer_invoice_list && this.state.customer_invoice_list.map((item, i) => {
                                                            //     total_invoice = total_invoice + Number(item.open_balance_foreign_currency)
                                                            // })

                                                            // this.state.customer_invoice_list && this.state.customer_invoice_list.map((item, i) => {
                                                            //     total_credit = total_credit + Number(item.open_balance_foreign_currency)
                                                            // })

                                                            // // to select all
                                                            // let array = this.state.customer_credit_list
                                                            // this.state.customer_credit_list && this.state.customer_credit_list.map((item, i) => {
                                                            //     if (total_invoice > 0) {
                                                            //         array[i] = { ...array[i], checked: true, used_amount: item.open_balance_foreign_currency }
                                                            //         total_invoice = total_invoice - item.open_balance_foreign_currency
                                                            //     }
                                                            // })
                                                            // // to select all


                                                            // let array_invoice = this.state.customer_invoice_list
                                                            // this.state.customer_invoice_list && this.state.customer_invoice_list.map((item, i) => {
                                                            //     if (total_credit > 0) {
                                                            //         if (total_credit >= item.open_balance_foreign_currency) {
                                                            //             array_invoice[i] = { ...array_invoice[i], checked: true, open_balance_foreign_currency: 0 }
                                                            //             total_credit = total_credit - item.open_balance_foreign_currency
                                                            //         } else if (total_credit < item.open_balance_foreign_currency) {
                                                            //             array_invoice[i] = { ...array_invoice[i], checked: true, open_balance_foreign_currency: item.open_balance_foreign_currency - total_credit }
                                                            //             total_credit = 0
                                                            //         }
                                                            //     }
                                                            // })
                                                            // this.setState({ customer_credit_list: array, customer_invoice_list: array_invoice })
                                                            // to apply credits to invoice

                                                            let credit_array = this.state.customer_credit_list ? this.state.customer_credit_list:[]; 
                                                            let invoice_array = this.state.customer_invoicelist?this.state.customer_invoicelist:[];
                                                            credit_array.forEach((credit,ind)=>{
                                                                invoice_array.forEach((invoice,k)=>{
                                                                    let inv_forgn_open_balance = invoice.open_balance_foreign_currency;
                                                                    let cre_forgn_open_balance = credit_array[ind].actual_available_credits;
                                                                    let cre_used_amt = credit_array[ind].used_amount ? credit_array[ind].used_amount:0;
                                                                    let inv_applied_amount = invoice_array[k].applied_amount ? invoice_array[k].applied_amount:0;
                                                                    let applied_invoices = credit_array[ind].applied_invoices ? credit_array[ind].applied_invoices:[];
                                                                    if(cre_forgn_open_balance > 0 && inv_forgn_open_balance > 0) {
                                                                    if(inv_forgn_open_balance <= cre_forgn_open_balance) {
                                                                        applied_invoices.push({ invoice_id : invoice.id, applied_amount: inv_forgn_open_balance });
                                                                        invoice_array[k] = {...invoice_array[k],applied_amount: ( inv_applied_amount + inv_forgn_open_balance),open_balance_foreign_currency:0,checked:true}
                                                                        credit_array[ind] = {...credit_array[ind],used_amount: ( cre_used_amt + inv_forgn_open_balance),actual_available_credits:(cre_forgn_open_balance - inv_forgn_open_balance),applied_invoices:applied_invoices,checked:true  }
                                                                    }
                                                                     else if(inv_forgn_open_balance > cre_forgn_open_balance) {
                                                                        applied_invoices.push({ invoice_id : invoice.id, applied_amount: cre_forgn_open_balance });
                                                                        invoice_array[k] = {...invoice_array[k],applied_amount:(inv_applied_amount + cre_forgn_open_balance),open_balance_foreign_currency:(inv_forgn_open_balance - cre_forgn_open_balance),checked:true }
                                                                        credit_array[ind] = {...credit_array[ind],used_amount:(cre_used_amt + cre_forgn_open_balance),actual_available_credits:0,applied_invoices:applied_invoices,checked:true  }
                                                                    }
                                                                }
                                                                });
                                                            });
                                                            this.setState({
                                                                customer_credit_list:credit_array,
                                                                customer_invoicelist:invoice_array
                                                            },()=>{
                                                                console.log("customer_credit_list....",this.state.customer_credit_list);
                                                                console.log("customer_invoicelist....",this.state.customer_invoicelist);
                                                            });
                                                        }}

                                                    >Auto Apply All</button>
                                                    <button className="btn btn-blue" onClick={() => {

                                        let credit_array = this.state.customer_credit_list;
                                        let array = this.state.customer_invoicelist;
                                        if(this.state.selected_customer_credit_index >=0 && credit_array[this.state.selected_customer_credit_index]) {
                                        array.forEach((arr,ind)=>{
                                            let applied_invoices = credit_array[this.state.selected_customer_credit_index]["applied_invoices"] ? credit_array[this.state.selected_customer_credit_index]["applied_invoices"]:[];
                                            let idx = _.findIndex(applied_invoices, function(o) { return o.invoice_id == arr.id; });
                                            if(idx >= 0) {
                                                let amt = arr.applied_amount ? Number(arr.applied_amount):0;
                                                let open_balance_after_apply = arr.open_balance_foreign_currency - amt;
                                                array[ind] = { ...array[ind], open_balance_foreign_currency: open_balance_after_apply,checked:false};
                                                this.setState({ customer_invoicelist: array });
                                            }
                                        });
                                        }

                                                    }}>Apply</button>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-xs-12">
                                                <p className="ct-data-title"><span>Step 2:</span> Select Invoices</p>
                                                <div className="report-table reconcile-table ctd-review col-md-12 col-xs-12 pad-no mar-t-no mar-btm">
                                                    <div className="table-responsive hide-v-scroll">
                                                        <table className="table detail-report minw-unset td-vm">
                                                            <thead>
                                                                <tr>
                                                                    <th>
                                                                        {/* <label className="custom-checkbox small">
                                                                            <input type="checkbox" name="all" />&nbsp;
                                      <span className="checkmark" />
                                                                        </label> */}
                                                                    </th>
                                                                    <th>Type</th>
                                                                    <th>Invoice No</th>
                                                                    <th>Date</th>
                                                                    <th className="text-right">Total Amount</th>
                                                                    <th className="text-right">Balance Due</th>
                                                                    <th className="text-right">Amount Applied</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.customer_invoicelist &&
                                                                    this.state.customer_invoicelist.map((item, i) => {
                                                                        return (
                                                                            <tr>
                                                                                <td>
                                                                                    <label className="custom-checkbox small mar-t-no">
                                                                                        <input type="checkbox" name="all"
                                                                                        disabled={this.state.is_auto_apply}
                                                                                           checked={this.state.customer_invoicelist[i].checked}
                                                                                           onChange={(e)=>{
                                                                                                if(e.target.checked == false) {
                                                                                                    let array = this.state.customer_invoicelist;
                                                                                                    array[i] = { ...array[i], applied_amount: '' }
                                                                                                    array[i] = { ...array[i], checked: false }
                                                                                                    this.setState({ customer_invoicelist: array},()=>{
                                                                                                        console.log("customer_invoicelist....796",this.state.customer_invoicelist);
                                                                                                    });


                                                                                                    // let idx = _.findIndex(applied_invoices, function(o) { return o.invoice_id == item.id; });
                                                                                                    // if(idx >= 0) {
                                                                                                    //     let pre_applied_amount = applied_invoices[idx]["applied_amount"]?applied_invoices[idx]["applied_amount"]:0;
                                                                                                    //     balance_after_used_amount_new = balance_after_used_amount_new + pre_applied_amount;
                                                                                                    // }
                                                                                                    // _.remove(applied_invoices, {invoice_id: item.id});
                                                                                                    // credit_array[current_index] = { ...credit_array[current_index], applied_invoices: applied_invoices,balance_after_used_amount:balance_after_used_amount_new };

                                                                                                    let credit_array = this.state.customer_credit_list;
                                                                                                    let selected_credit_available_amount = this.state.customer_credit_list[this.state.selected_customer_credit_index] && this.state.customer_credit_list[this.state.selected_customer_credit_index]["balance_after_used_amount"]?this.state.customer_credit_list[this.state.selected_customer_credit_index]["balance_after_used_amount"]:0;
                                                                                                    let balance_after_used_amount_new = credit_array[this.state.selected_customer_credit_index]["balance_after_used_amount"] ? credit_array[this.state.selected_customer_credit_index]["balance_after_used_amount"]:0;

                                                                                                    
                                                                                                    if(this.state.selected_customer_credit_index >=0 && credit_array[this.state.selected_customer_credit_index]) {
                                                                                                        let applied_invoices = credit_array[this.state.selected_customer_credit_index]["applied_invoices"] ? credit_array[this.state.selected_customer_credit_index]["applied_invoices"]:[];

                                                                                                        let idx = _.findIndex(applied_invoices, function(o) { return o.invoice_id == item.id; });
                                                                                                        if(idx >= 0) {
                                                                                                                let pre_applied_amount = applied_invoices[idx]["applied_amount"]?applied_invoices[idx]["applied_amount"]:0;
                                                                                                                balance_after_used_amount_new = balance_after_used_amount_new + pre_applied_amount;
                                                                                                        }
                                                                                                        _.remove(applied_invoices, {invoice_id: item.id});
                                                                                                        credit_array[this.state.selected_customer_credit_index] = { ...credit_array[this.state.selected_customer_credit_index], applied_invoices: applied_invoices,balance_after_used_amount:balance_after_used_amount_new };
                                                                                                        this.setState({ customer_credit_list: credit_array },()=>{
                                                                                                            console.log("customer_credit_list....814",this.state.customer_credit_list);
                                                                                                        });
                                                                                                    }
                                                                                                } else {
                                                                                                    
                                                                                                    let array = this.state.customer_invoicelist;
                                                                                                    
                                                                                                    let credit_array = this.state.customer_credit_list;
                                                                                                    let inv_foreign_balance = array[i].open_balance_foreign_currency;

                                                                                                    console.log("this.state.customer_credit_list[this.state.selected_customer_credit_index]...",this.state.customer_credit_list[this.state.selected_customer_credit_index]);
                                                                                                    console.log("this.state.selected_customer_credit_index...",this.state.selected_customer_credit_index);

                                                                                                    let selected_credit_available_amount = this.state.customer_credit_list[this.state.selected_customer_credit_index] && this.state.customer_credit_list[this.state.selected_customer_credit_index]["balance_after_used_amount"]?this.state.customer_credit_list[this.state.selected_customer_credit_index]["balance_after_used_amount"]:0;
                                                                                                    
                                                                                                    if(selected_credit_available_amount > 0) {
                                                                                                        
                                                                                                    let current_index = this.state.selected_customer_credit_index;
                                                                                                    let applied_invoices = credit_array[this.state.selected_customer_credit_index]["applied_invoices"] ? credit_array[this.state.selected_customer_credit_index]["applied_invoices"]:[];
                                                                                                    let balance_after_used_amount_new = credit_array[this.state.selected_customer_credit_index]["balance_after_used_amount"] ? credit_array[this.state.selected_customer_credit_index]["balance_after_used_amount"]:0;

                                                                                                    
                                                                                                    

                                                                                                    if(inv_foreign_balance <= selected_credit_available_amount){
                                                                                                     array[i] = { ...array[i], applied_amount: inv_foreign_balance,checked:true }
                                                                                                     balance_after_used_amount_new = Number(balance_after_used_amount_new) - inv_foreign_balance;
                                                                                                     let idx = _.findIndex(applied_invoices, function(o) { return o.invoice_id == item.id; });
                                                                                                     if(idx >= 0) {
                                                                                                         applied_invoices[idx] = {invoice_id:item.id, applied_amount:inv_foreign_balance};
                                                                                                     } else {
                                                                                                         applied_invoices.push({invoice_id:item.id, applied_amount:inv_foreign_balance});
                                                                                                     }

                                                                                                } else {
                                                                                                    array[i] = { ...array[i], applied_amount: selected_credit_available_amount,checked:true }
                                                                                                    balance_after_used_amount_new = Number(balance_after_used_amount_new) - selected_credit_available_amount;
                                                                                                    let idx = _.findIndex(applied_invoices, function(o) { return o.invoice_id == item.id; });
                                                                                                    if(idx >= 0) {
                                                                                                        applied_invoices[idx] = {invoice_id:item.id, applied_amount:selected_credit_available_amount};
                                                                                                    } else {
                                                                                                        applied_invoices.push({invoice_id:item.id, applied_amount:selected_credit_available_amount});
                                                                                                    }
                                                                                                }
                                                                                                credit_array[current_index] = { ...credit_array[current_index], applied_invoices: applied_invoices,balance_after_used_amount:balance_after_used_amount_new };
                                                                                                this.setState({ customer_invoicelist: array, customer_credit_list:credit_array },()=>{
                                                                                                    console.log("customer_credit_list.....839",this.state.customer_credit_list);
                                                                                                    console.log("customer_invoicelist.....840",this.state.customer_invoicelist);
                                                                                                });
                                                                                            }
                                                                                                }
                                                                                           }}
                                                                                        />&nbsp;
                                                                                            <span className="checkmark" />
                                                                                    </label>
                                                                                </td>
                                                                                <td>Invoice</td>
                                                                                <td>{item.invoice_number}</td>
                                                                                {/* <td>{moment(item.invoice_date).format(
                                                                                        this.state.date_format
                                                                                        )}</td> */}
                                                                                         <td>{item.invoice_date}</td>
                                                                                <td className="text-right"><Comma value={item.grand_total_foreign_currency}/></td>
                                                                                {/* <td className="text-right">{item.open_balance_foreign_currency}</td> */}
                                                                                <td className="text-right"><Comma value={item.actual_open_balance_foreign_currency}/></td>
                                                                                <td><input type="text" className="form-control  text-right" name
                                                                                    value={this.state.customer_invoicelist[i].applied_amount}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.value <= item.open_balance_foreign_currency) {
                                                                                            let current_value = e.target.value;
                                                                                            let selected_credit_used_amount = this.state.customer_credit_list[this.state.selected_customer_credit_index] && this.state.customer_credit_list[this.state.selected_customer_credit_index]["balance_after_used_amount"]?this.state.customer_credit_list[this.state.selected_customer_credit_index]["balance_after_used_amount"]:0;
                                                                                            if(Number(e.target.value) <= selected_credit_used_amount){
                                                                                            let array = this.state.customer_invoicelist
                                                                                            array[i] = { ...array[i], applied_amount: e.target.value }
                                                                                            if (e.target.value !== '') {
                                                                                                array[i] = { ...array[i], checked: true }
                                                                                            } else {
                                                                                                array[i] = { ...array[i], checked: false }
                                                                                            }
                                                                                            this.setState({ customer_invoicelist: array },()=>{
                                                                                               
                                                                                            });
                                                                                        } else {
                                                                                            let array = this.state.customer_invoicelist;
                                                                                            array[i] = { ...array[i], applied_amount: '' };
                                                                                            this.setState({ customer_invoicelist: array });
                                                                                            alert('Cannot apply more than amount given in Credits to apply')
                                                                                        }

                                                                                        } else {
                                                                                            let array = this.state.customer_invoicelist;
                                                                                            array[i] = { ...array[i], applied_amount: '' };
                                                                                            this.setState({ customer_invoicelist: array });
                                                                                            alert('Cannot apply more than Invoice balance due')
                                                                                        }

                                                                                    }}

                                                                                    onBlur={(e) => {
                                                                                            let credit_array = this.state.customer_credit_list;
                                                                                            let array = this.state.customer_invoicelist;
                                                                                            if(this.state.selected_customer_credit_index >=0 && credit_array[this.state.selected_customer_credit_index]) {
                                                                                                let current_index = this.state.selected_customer_credit_index;
                                                                                                let applied_invoices = credit_array[this.state.selected_customer_credit_index]["applied_invoices"] ? credit_array[this.state.selected_customer_credit_index]["applied_invoices"]:[];
                                                                                                let balance_after_used_amount_new = credit_array[this.state.selected_customer_credit_index]["balance_after_used_amount"] ? credit_array[this.state.selected_customer_credit_index]["balance_after_used_amount"]:0;
                                                                                                


                                                                                                
                                                                                                if (e.target.value !== '') {
                                                                                                    let idx = _.findIndex(applied_invoices, function(o) { return o.invoice_id == item.id; });
                                                                                                    if(idx >= 0) {
                                                                                                        applied_invoices[idx] = {invoice_id:item.id, applied_amount:e.target.value};
                                                                                                    } else {
                                                                                                        applied_invoices.push({invoice_id:item.id, applied_amount:e.target.value});
                                                                                                    }
                                                                                                    
                                                                                                    balance_after_used_amount_new = Number(balance_after_used_amount_new) - Number(e.target.value);
                                                                                                    credit_array[current_index] = { ...credit_array[current_index], applied_invoices: applied_invoices,balance_after_used_amount:balance_after_used_amount_new };
                                                                                                } else {
                                                                                                    let idx = _.findIndex(applied_invoices, function(o) { return o.invoice_id == item.id; });
                                                                                                    if(idx >= 0) {
                                                                                                        let pre_applied_amount = applied_invoices[idx]["applied_amount"]?applied_invoices[idx]["applied_amount"]:0;
                                                                                                        balance_after_used_amount_new = balance_after_used_amount_new + pre_applied_amount;
                                                                                                    }
                                                                                                    _.remove(applied_invoices, {invoice_id: item.id});
                                                                                                    credit_array[current_index] = { ...credit_array[current_index], applied_invoices: applied_invoices,balance_after_used_amount:balance_after_used_amount_new };
                                                                                                }
                                                                                                this.setState({ customer_credit_list: credit_array,customer_invoicelist:array },()=>{
                                                                                                    console.log("state.customer_credit_list.....804",this.state.customer_credit_list);
                                                                                                });
                                                                                            }
                                                                                    }}
                                                                                /></td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 col-xs-12 text-center mar-btm pad-btm">
                                                    <button className="btn btn-blue mar-rgt-5"
                                                        type='button'
                                                        onClick={() => {
                                                            this.setState({is_auto_apply:false});
                                                            // to deselect all
                                                            // let array = this.state.customer_credit_list
                                                            // this.state.customer_credit_list && this.state.customer_credit_list.map((item, i) => {
                                                            //     array[i] = { ...array[i], checked: false, used_amount: '' }
                                                            // })
                                                            // this.setState({ customer_credit_list: array })
                                                            // to deselect all

                                                            
                                                            let credit_array = this.state.customer_credit_list ? this.state.customer_credit_list:[]; 
                                                            let invoice_array = this.state.customer_invoicelist?this.state.customer_invoicelist:[];
                                                            credit_array.forEach((credit,ind)=>{
                                                                let cre_forgn_open_balance = credit_array[ind].available_credits;
                                                                credit_array[ind] = {...credit_array[ind],used_amount: '',actual_available_credits:cre_forgn_open_balance,applied_invoices:[],checked:false  }
                                                            });

                                                            invoice_array.forEach((invoice,k)=>{
                                                                let inv_forgn_open_balance = invoice.actual_open_balance_foreign_currency;
                                                                invoice_array[k] = {...invoice_array[k],applied_amount: '',open_balance_foreign_currency:inv_forgn_open_balance,checked:false}
                                                            });

                                                            this.setState({
                                                                customer_credit_list:credit_array,
                                                                customer_invoicelist:invoice_array
                                                            },()=>{
                                                                console.log("customer_credit_list....",this.state.customer_credit_list);
                                                                console.log("customer_invoicelist....",this.state.customer_invoicelist);
                                                            });

                                                        }}

                                                    >Unapply All</button>
                                                    <button className="btn btn-blue" onClick={()=>{
                                                        
                     let credit_array = this.state.customer_credit_list;
                     let array = this.state.customer_invoicelist;
                     if(this.state.selected_customer_credit_index >=0 && credit_array[this.state.selected_customer_credit_index]) {
                     array.forEach((arr,ind)=>{
                         let applied_invoices = credit_array[this.state.selected_customer_credit_index]["applied_invoices"] ? credit_array[this.state.selected_customer_credit_index]["applied_invoices"]:[];
                         let idx = _.findIndex(applied_invoices, function(o) { return o.invoice_id == arr.id; });
                         if(idx >= 0) {
                             let amt = arr.applied_amount ? Number(arr.applied_amount):0;
                             let open_balance_after_apply = arr.open_balance_foreign_currency + amt;
                             array[ind] = { ...array[ind], open_balance_foreign_currency: open_balance_after_apply,applied_amount:''};
                            //  this.setState({ customer_invoicelist: array });
                         }
                     });
                     this.setState({ customer_invoicelist: array },()=>{
                         let available_credits = credit_array[this.state.selected_customer_credit_index]["available_credits"];
                        credit_array[this.state.selected_customer_credit_index] = { ...credit_array[this.state.selected_customer_credit_index], applied_invoices:[],balance_after_used_amount:available_credits};
                     });
                                                    }
                                                    }}>Unapply</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="ven-data-review" className="col-md-12 col-xs-12 tab-pane fade pad-no in">
                                        <div className="row">
                                            <div className="content-top col-md-12 col-xs-12">
                                                <form className="custom-form row">
                                                    <div className="form-group col-md-3">
                                                        <label>Currency</label>
                                                        <select
                                                            className="selectpicker form-control hh w-100 "
                                                            id="invoice_curr_id_v"
                                                            data-live-search="true"
                                                            title={`Choose`}
                                                            value={this.state.CurrentSelectedCurr}
                                                            // data-width="150%"
                                                            onChange={(e) => {
                                                                this.findInSubAccountList_v(e.target.value);
                                                            }}
                                                        >
                                                            {this.state.currencies &&
                                                                this.state.currencies.map((item) => {
                                                                    // console.log(
                                                                    //     "sjhkalshasjaj",
                                                                    //     this.state.currencies
                                                                    // );

                                                                    if (item.code !== "ALL") {
                                                                        return <option value={item}> {item}</option>;
                                                                    }
                                                                })}
                                                        </select>
                                                    </div>
                                                    {/* <div className="form-group col-md-3 no-edit"> */}
                                                    <div className="form-group col-md-3">
                                                        <label>Accounts Payable</label>
                                                        <select
                                                            className="selectpicker form-control add-new "
                                                            id="account_id_v"
                                                            data-live-search="true"
                                                            title={`Choose`}
                                                            data-width="100%"
                                                            onChange={(e) => {
                                                                let value = e.target.value;
                                                                jQuery("#account_id_v").val(value);
                                                                this.setState({
                                                                    account_v: value
                                                                },()=>{
                                                                    this.handleChanges_v();
                                                                });
                                                             }}
                                                            // disabled={true}
                                                            value={this.state.account_v}
                                                        >
                                                            {this.state.SubAccountList != undefined &&
                                                                this.state.SubAccountList.map((item, index) => {
                                                                    if((item.currency != '' && item.currency == this.state.CurrentSelectedCurr) && item.name != 'Accounts Payable') {
                                                                    return (
                                                                        <option value={item.id}>{item.name}</option>
                                                                    );
                                                                } else {
                                                                    return '';
                                                                }
                                                                })}
                                                        </select>


                                                    </div>

                                                    <div className="form-group col-md-3">
                                                        <label>Vendor Name</label>

                                                        <select
                                                            className="selectpicker form-control add-new"
                                                            data-live-search="true"
                                                            title="Choose vendor"
                                                            id="variable_pay_type_v"
                                                            value={this.state.vendorName}
                                                            onChange={(e) => {
                                                                this.handleChange_v(e);
                                                            }}
                                                        >
                                                            <option>Choose</option>
                                                            {this.state.vendor_list &&
                                                                this.state.vendor_list.map((item) => {
                                                                    return (
                                                                        <option
                                                                            value={item.id}
                                                                            data-status={item.id}
                                                                            disabled={item.status == 2?true:false}
                                                                            style={{display:item.status == 2?'none':'block'}}
                                                                        >
                                                                            {item.vendor_name}
                                                                        </option>
                                                                    );
                                                                })}
                                                        </select>
                                                    </div>
                                                    {/* <div className="form-group col-md-3">
                                                        <label>Job List</label>
                                                        {
                                                            <select
                                                                className="selectpicker form-control add-new"
                                                                data-live-search="true"
                                                                title="Choose a job"
                                                                id="variable_pay_type_job_v"
                                                                onChange={() => {
                                                                    this.handleChanges_v();
                                                                }}
                                                            >
                                                                <option>Choose</option>
                                                                {this.state.customer_and_job_lists &&
                                                                    this.state.customer_and_job_lists.map(
                                                                        (item) => {
                                                                            return (
                                                                                <option
                                                                                    value={item.id}
                                                                                    data-status={item.id}
                                                                                >
                                                                                    {item.name}
                                                                                </option>
                                                                            );
                                                                        }
                                                                    )}
                                                            </select>
                                                        }
                                                    </div> */}


                                                </form>
                                            </div>
                                            <div className="col-md-6 col-xs-12">
                                                <p className="ct-data-title"><span>Step 1:</span> Select Payments / Credits</p>
                                                <div className="report-table reconcile-table ctd-review col-md-12 col-xs-12 pad-no mar-t-no mar-btm">
                                                    <div className="table-responsive hide-v-scroll">
                                                        <table className="table detail-report minw-unset td-vm">
                                                            <thead>
                                                                <tr>
                                                                    <th>
                                                                        {/* <label className="custom-checkbox small">
                                                                            <input type="checkbox" name="all" />&nbsp;
                                      <span className="checkmark" />
                                                                        </label> */}
                                                                    </th>
                                                                    <th>Type</th>
                                                                    <th>Credit/Payment No</th>
                                                                    <th>Date</th>
                                                                    <th className="text-right">Total Amount</th>
                                                                    <th className="text-right">Credits Available</th>
                                                                    <th className="text-right">Credits to Apply</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                            {this.state.vendor_credit_list &&
    this.state.vendor_credit_list.map((item, i) => {
        return (
            <tr>
                <td>
                    <label className="custom-checkbox small mar-t-no">
                        <input type="checkbox" name="all"
                            checked={this.state.vendor_credit_list[i].checked}
                            disabled={this.state.is_auto_apply}

                            onChange={(e) => {
                                let array = this.state.vendor_credit_list
                                if (e.target.checked) {
                                    array[i] = { ...array[i], checked: true,used_amount: item.available_credits,balance_after_used_amount:item.available_credits }
                                    this.setState({ vendor_credit_list: array,selected_vendor_credit_index:i })
                                } else {
                                    array[i] = { ...array[i], checked: false, used_amount: '',balance_after_used_amount:0 }
                                    this.setState({ vendor_credit_list: array,selected_vendor_credit_index: -1 });

                                    let invoice_array = this.state.vendor_bill_list;
                                    if(array[i]) {
                                        invoice_array.forEach((arr,ind)=>{
                                        let applied_invoices = array[i]["applied_invoices"] ? array[i]["applied_invoices"]:[];
                                        let idx = _.findIndex(applied_invoices, function(o) { return o.invoice_id == arr.id; });
                                        if(idx >= 0) {
                                            let amt = arr.applied_amount ? Number(arr.applied_amount):0;
                                            let applied_amount = arr.applied_amount - amt;
                                            if(applied_amount == 0){
                                                applied_amount = '';
                                            }
                                            let open_balance_after_apply = arr.open_balance_foreign_currency + amt;
                                            // open_balance_foreign_currency: open_balance_after_apply,
                                            invoice_array[ind] = { ...invoice_array[ind],checked:false,applied_amount:applied_amount};
                                        }
                                    });
                                    this.setState({ vendor_bill_list: invoice_array },()=>{
                                        array[i] = { ...array[i], applied_invoices:[] }
                                        this.setState({ vendor_credit_list: array});
                                    });
                                    }
                           
                                }
                            }}

                        />&nbsp;
<span className="checkmark" />
                    </label>
                </td>
                {/* <td>{item.credit_memo_type == 1? 'Credit':item.credit_memo_type == 2?'Payment':'Cheque'}</td> */}
                <td>{item.credit_type_as_text}</td>
                <td>{item.credit_number}</td>
                {/* <td>{moment(item.credit_date).format(
                                     this.state.date_format
                                    )}</td> */}
                <td>{item.credit_date}</td>
                <td className="text-right"><Comma value={item.amount }/></td>
                <td className="text-right"><Comma value={item.available_credits}/></td>
                <td><input type="text" className="form-control  text-right" name
                    value={this.state.vendor_credit_list[i].used_amount}
                    disabled={this.state.vendor_credit_list[i].applied_invoices && this.state.vendor_credit_list[i].applied_invoices.length > 0}
                    onChange={(e) => {
                        if (e.target.value <= item.available_credits) {
                            let array = this.state.vendor_credit_list
                            array[i] = { ...array[i], used_amount: e.target.value }
                            if (e.target.value !== '') {
                                array[i] = { ...array[i], checked: true, balance_after_used_amount:e.target.value }
                            } else {
                                array[i] = { ...array[i], checked: false, balance_after_used_amount:0 }
                            }
                            // modified on 04-07-2022
                            // this.setState({ vendor_credit_list: array },()=>{
                                this.setState({ vendor_credit_list: array,selected_vendor_credit_index:i },()=>{
                                console.log("vendor_credit_list...651",this.state.vendor_credit_list);
                            })
                        } else {
                            alert('please use less than or equal avilable available credit')
                        }

                    }}
                /></td>
            </tr>
        )
    })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 col-xs-12 text-center mar-btm pad-btm">
                                                    <button className="btn btn-blue mar-rgt-5" onClick={()=>{
                                                        this.setState({is_auto_apply:true});

                                                        let credit_array = this.state.vendor_credit_list ? this.state.vendor_credit_list:[]; 
                                                        let invoice_array = this.state.vendor_bill_list?this.state.vendor_bill_list:[];
                                                        credit_array.forEach((credit,ind)=>{
                                                            invoice_array.forEach((invoice,k)=>{
                                                                let inv_forgn_open_balance = invoice.open_balance_foreign_currency;
                                                                let cre_forgn_open_balance = credit_array[ind].actual_available_credits;
                                                                let cre_used_amt = credit_array[ind].used_amount ? credit_array[ind].used_amount:0;
                                                                let inv_applied_amount = invoice_array[k].applied_amount ? invoice_array[k].applied_amount:0;
                                                                let applied_invoices = credit_array[ind].applied_invoices ? credit_array[ind].applied_invoices:[];
                                                                if(cre_forgn_open_balance > 0 && inv_forgn_open_balance > 0) {
                                                                if(inv_forgn_open_balance <= cre_forgn_open_balance) {
                                                                    applied_invoices.push({ invoice_id : invoice.id, applied_amount: inv_forgn_open_balance });
                                                                    invoice_array[k] = {...invoice_array[k],applied_amount: ( inv_applied_amount + inv_forgn_open_balance),open_balance_foreign_currency:0,checked:true}
                                                                    credit_array[ind] = {...credit_array[ind],used_amount: ( cre_used_amt + inv_forgn_open_balance),actual_available_credits:(cre_forgn_open_balance - inv_forgn_open_balance),applied_invoices:applied_invoices,checked:true  }
                                                                }
                                                                 else if(inv_forgn_open_balance > cre_forgn_open_balance) {
                                                                    applied_invoices.push({ invoice_id : invoice.id, applied_amount: cre_forgn_open_balance });
                                                                    invoice_array[k] = {...invoice_array[k],applied_amount:(inv_applied_amount + cre_forgn_open_balance),open_balance_foreign_currency:(inv_forgn_open_balance - cre_forgn_open_balance),checked:true }
                                                                    credit_array[ind] = {...credit_array[ind],used_amount:(cre_used_amt + cre_forgn_open_balance),actual_available_credits:0,applied_invoices:applied_invoices,checked:true  }
                                                                }
                                                            }
                                                            });
                                                        });
                                                        this.setState({
                                                            vendor_credit_list:credit_array,
                                                            vendor_bill_list:invoice_array
                                                        },()=>{
                                                            console.log("vendor_credit_list....",this.state.vendor_credit_list);
                                                            console.log("vendor_bill_list....",this.state.vendor_bill_list);
                                                        });
                                                    }}>Auto Apply All</button>
                                                    <button className="btn btn-blue" onClick={()=>{
                                                          let credit_array = this.state.vendor_credit_list;
                                                          let array = this.state.vendor_bill_list;
                                                          if(this.state.selected_vendor_credit_index >=0 && credit_array[this.state.selected_vendor_credit_index]) {
                                                          array.forEach((arr,ind)=>{
                                                              let applied_invoices = credit_array[this.state.selected_vendor_credit_index]["applied_invoices"] ? credit_array[this.state.selected_vendor_credit_index]["applied_invoices"]:[];
                                                              let idx = _.findIndex(applied_invoices, function(o) { return o.invoice_id == arr.id; });
                                                              if(idx >= 0) {
                                                                  let amt = arr.applied_amount ? Number(arr.applied_amount):0;
                                                                  let open_balance_after_apply = arr.open_balance_foreign_currency - amt;
                                                                  array[ind] = { ...array[ind], open_balance_foreign_currency: open_balance_after_apply,checked:false};
                                                                  this.setState({ vendor_bill_list: array });
                                                              }
                                                          });
                                                          }
                                                    }}>Apply</button>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-xs-12">
                                                <p className="ct-data-title"><span>Step 2:</span> Select Bills</p>
                                                <div className="report-table reconcile-table ctd-review col-md-12 col-xs-12 pad-no mar-t-no mar-btm">
                                                    <div className="table-responsive hide-v-scroll">
                                                        <table className="table detail-report minw-unset td-vm">
                                                            <thead>
                                                                <tr>
                                                                    <th>
                                                                        {/* <label className="custom-checkbox small">
                                                                            <input type="checkbox" name="all" />&nbsp;
                                      <span className="checkmark" />
                                                                        </label> */}
                                                                    </th>
                                                                    <th>Type</th>
                                                                    <th>Invoice No</th>
                                                                    <th>Date</th>
                                                                    <th className="text-right">Total Amount</th>
                                                                    <th className="text-right">Balance Due</th>
                                                                    <th className="text-right">Amount Applied</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                            {this.state.vendor_bill_list &&
    this.state.vendor_bill_list.map((item, i) => {
        return (
            <tr>
                <td>
                    <label className="custom-checkbox small mar-t-no">
                        <input type="checkbox" name="all"
                        disabled={this.state.is_auto_apply}
                           checked={this.state.vendor_bill_list[i].checked}
                           onChange={(e)=>{
                                if(e.target.checked == false) {
                                    let array = this.state.vendor_bill_list;
                                    array[i] = { ...array[i], applied_amount: '' }
                                    array[i] = { ...array[i], checked: false }
                                    this.setState({ vendor_bill_list: array},()=>{
                                        console.log("vendor_bill_list....796",this.state.vendor_bill_list);
                                    });


                                    // let idx = _.findIndex(applied_invoices, function(o) { return o.invoice_id == item.id; });
                                    // if(idx >= 0) {
                                    //     let pre_applied_amount = applied_invoices[idx]["applied_amount"]?applied_invoices[idx]["applied_amount"]:0;
                                    //     balance_after_used_amount_new = balance_after_used_amount_new + pre_applied_amount;
                                    // }
                                    // _.remove(applied_invoices, {invoice_id: item.id});
                                    // credit_array[current_index] = { ...credit_array[current_index], applied_invoices: applied_invoices,balance_after_used_amount:balance_after_used_amount_new };

                                    let credit_array = this.state.vendor_credit_list;
                                    let selected_credit_available_amount = this.state.vendor_credit_list[this.state.selected_vendor_credit_index] && this.state.vendor_credit_list[this.state.selected_vendor_credit_index]["balance_after_used_amount"]?this.state.vendor_credit_list[this.state.selected_vendor_credit_index]["balance_after_used_amount"]:0;
                                    let balance_after_used_amount_new = credit_array[this.state.selected_vendor_credit_index]["balance_after_used_amount"] ? credit_array[this.state.selected_vendor_credit_index]["balance_after_used_amount"]:0;

                                    
                                    if(this.state.selected_vendor_credit_index >=0 && credit_array[this.state.selected_vendor_credit_index]) {
                                        let applied_invoices = credit_array[this.state.selected_vendor_credit_index]["applied_invoices"] ? credit_array[this.state.selected_vendor_credit_index]["applied_invoices"]:[];

                                        let idx = _.findIndex(applied_invoices, function(o) { return o.invoice_id == item.id; });
                                        if(idx >= 0) {
                                                let pre_applied_amount = applied_invoices[idx]["applied_amount"]?applied_invoices[idx]["applied_amount"]:0;
                                                balance_after_used_amount_new = balance_after_used_amount_new + pre_applied_amount;
                                        }
                                        _.remove(applied_invoices, {invoice_id: item.id});
                                        credit_array[this.state.selected_vendor_credit_index] = { ...credit_array[this.state.selected_vendor_credit_index], applied_invoices: applied_invoices,balance_after_used_amount:balance_after_used_amount_new };
                                        this.setState({ vendor_credit_list: credit_array },()=>{
                                            console.log("vendor_credit_list....814",this.state.vendor_credit_list);
                                        });
                                    }
                                } else {
                                    
                                    let array = this.state.vendor_bill_list;
                                    
                                    let credit_array = this.state.vendor_credit_list;
                                    let inv_foreign_balance = array[i].open_balance_foreign_currency;

                                    console.log("this.state.vendor_credit_list[this.state.selected_vendor_credit_index]...",this.state.vendor_credit_list[this.state.selected_vendor_credit_index]);
                                    console.log("this.state.selected_vendor_credit_index...",this.state.selected_vendor_credit_index);

                                    let selected_credit_available_amount = this.state.vendor_credit_list[this.state.selected_vendor_credit_index] && this.state.vendor_credit_list[this.state.selected_vendor_credit_index]["balance_after_used_amount"]?this.state.vendor_credit_list[this.state.selected_vendor_credit_index]["balance_after_used_amount"]:0;
                                    
                                    if(selected_credit_available_amount > 0) {
                                        
                                    let current_index = this.state.selected_vendor_credit_index;
                                    let applied_invoices = credit_array[this.state.selected_vendor_credit_index]["applied_invoices"] ? credit_array[this.state.selected_vendor_credit_index]["applied_invoices"]:[];
                                    let balance_after_used_amount_new = credit_array[this.state.selected_vendor_credit_index]["balance_after_used_amount"] ? credit_array[this.state.selected_vendor_credit_index]["balance_after_used_amount"]:0;

                                    
                                    

                                    if(inv_foreign_balance <= selected_credit_available_amount){
                                     array[i] = { ...array[i], applied_amount: inv_foreign_balance,checked:true }
                                     balance_after_used_amount_new = Number(balance_after_used_amount_new) - inv_foreign_balance;
                                     let idx = _.findIndex(applied_invoices, function(o) { return o.invoice_id == item.id; });
                                     if(idx >= 0) {
                                         applied_invoices[idx] = {invoice_id:item.id, applied_amount:inv_foreign_balance};
                                     } else {
                                         applied_invoices.push({invoice_id:item.id, applied_amount:inv_foreign_balance});
                                     }

                                } else {
                                    array[i] = { ...array[i], applied_amount: selected_credit_available_amount,checked:true }
                                    balance_after_used_amount_new = Number(balance_after_used_amount_new) - selected_credit_available_amount;
                                    let idx = _.findIndex(applied_invoices, function(o) { return o.invoice_id == item.id; });
                                    if(idx >= 0) {
                                        applied_invoices[idx] = {invoice_id:item.id, applied_amount:selected_credit_available_amount};
                                    } else {
                                        applied_invoices.push({invoice_id:item.id, applied_amount:selected_credit_available_amount});
                                    }
                                }
                                credit_array[current_index] = { ...credit_array[current_index], applied_invoices: applied_invoices,balance_after_used_amount:balance_after_used_amount_new };
                                this.setState({ vendor_bill_list: array, vendor_credit_list:credit_array },()=>{
                                    console.log("vendor_credit_list.....839",this.state.vendor_credit_list);
                                    console.log("vendor_bill_list.....840",this.state.vendor_bill_list);
                                });
                            }
                                }
                           }}
                        />&nbsp;
                            <span className="checkmark" />
                    </label>
                </td>
                <td>Invoice</td>
                <td>{item.invoice_number}</td>
                <td>{moment(item.invoice_date).format(
                                     this.state.date_format
                                    )}</td>
                <td className="text-right"><Comma value={item.grand_total_foreign_currency}/></td>
                {/* <td className="text-right">{item.open_balance_foreign_currency}</td> */}
                <td className="text-right"><Comma value={item.actual_open_balance_foreign_currency}/></td>
                <td><input type="text" className="form-control  text-right" name
                    value={this.state.vendor_bill_list[i].applied_amount}
                    onChange={(e) => {
                        if (e.target.value <= item.open_balance_foreign_currency) {
                            let current_value = e.target.value;
                            let selected_credit_used_amount = this.state.vendor_credit_list[this.state.selected_vendor_credit_index] && this.state.vendor_credit_list[this.state.selected_vendor_credit_index]["balance_after_used_amount"]?this.state.vendor_credit_list[this.state.selected_vendor_credit_index]["balance_after_used_amount"]:0;
                            if(Number(e.target.value) <= selected_credit_used_amount){
                            let array = this.state.vendor_bill_list
                            array[i] = { ...array[i], applied_amount: e.target.value }
                            if (e.target.value !== '') {
                                array[i] = { ...array[i], checked: true }
                            } else {
                                array[i] = { ...array[i], checked: false }
                            }
                            this.setState({ vendor_bill_list: array },()=>{
                               
                            });
                        } else {
                            let array = this.state.vendor_bill_list;
                            array[i] = { ...array[i], applied_amount: '' };
                            this.setState({ vendor_bill_list: array });
                            alert('Cannot apply more than amount given in Credits to apply')
                        }

                        } else {
                            let array = this.state.vendor_bill_list;
                            array[i] = { ...array[i], applied_amount: '' };
                            this.setState({ vendor_bill_list: array });
                            alert('Cannot apply more than Invoice balance due')
                        }

                    }}

                    onBlur={(e) => {
                            let credit_array = this.state.vendor_credit_list;
                            let array = this.state.vendor_bill_list;
                            if(this.state.selected_vendor_credit_index >=0 && credit_array[this.state.selected_vendor_credit_index]) {
                                let current_index = this.state.selected_vendor_credit_index;
                                let applied_invoices = credit_array[this.state.selected_vendor_credit_index]["applied_invoices"] ? credit_array[this.state.selected_vendor_credit_index]["applied_invoices"]:[];
                                let balance_after_used_amount_new = credit_array[this.state.selected_vendor_credit_index]["balance_after_used_amount"] ? credit_array[this.state.selected_vendor_credit_index]["balance_after_used_amount"]:0;
                                


                                
                                if (e.target.value !== '') {
                                    let idx = _.findIndex(applied_invoices, function(o) { return o.invoice_id == item.id; });
                                    if(idx >= 0) {
                                        applied_invoices[idx] = {invoice_id:item.id, applied_amount:e.target.value};
                                    } else {
                                        applied_invoices.push({invoice_id:item.id, applied_amount:e.target.value});
                                    }
                                    
                                    balance_after_used_amount_new = Number(balance_after_used_amount_new) - Number(e.target.value);
                                    credit_array[current_index] = { ...credit_array[current_index], applied_invoices: applied_invoices,balance_after_used_amount:balance_after_used_amount_new };
                                } else {
                                    let idx = _.findIndex(applied_invoices, function(o) { return o.invoice_id == item.id; });
                                    if(idx >= 0) {
                                        let pre_applied_amount = applied_invoices[idx]["applied_amount"]?applied_invoices[idx]["applied_amount"]:0;
                                        balance_after_used_amount_new = balance_after_used_amount_new + pre_applied_amount;
                                    }
                                    _.remove(applied_invoices, {invoice_id: item.id});
                                    credit_array[current_index] = { ...credit_array[current_index], applied_invoices: applied_invoices,balance_after_used_amount:balance_after_used_amount_new };
                                }
                                this.setState({ vendor_credit_list: credit_array,vendor_bill_list:array },()=>{
                                    console.log("state.vendor_credit_list.....804",this.state.vendor_credit_list);
                                });
                            }
                    }}
                /></td>
            </tr>
        )
    })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 col-xs-12 text-center mar-btm pad-btm">
                                                    <button className="btn btn-blue mar-rgt-5" onClick={()=>{
                                                        this.setState({is_auto_apply:false});

                                                        let credit_array = this.state.vendor_credit_list ? this.state.vendor_credit_list:[]; 
                                                        let invoice_array = this.state.vendor_bill_list?this.state.vendor_bill_list:[];
                                                        credit_array.forEach((credit,ind)=>{
                                                            let cre_forgn_open_balance = credit_array[ind].available_credits;
                                                            credit_array[ind] = {...credit_array[ind],used_amount: '',actual_available_credits:cre_forgn_open_balance,applied_invoices:[],checked:false  }
                                                        });
                                                        
                                                        invoice_array.forEach((invoice,k)=>{
                                                            let inv_forgn_open_balance = invoice.actual_open_balance_foreign_currency;
                                                            invoice_array[k] = {...invoice_array[k],applied_amount: '',open_balance_foreign_currency:inv_forgn_open_balance,checked:false}
                                                        });
                                                        
                                                        this.setState({
                                                            vendor_credit_list:credit_array,
                                                            vendor_bill_list:invoice_array
                                                        },()=>{
                                                            console.log("vendor_credit_list....",this.state.vendor_credit_list);
                                                            console.log("vendor_bill_list....",this.state.vendor_bill_list);
                                                        });

                                                    }}>Unapply All</button>
                                                    <button className="btn btn-blue" onClick={()=>{
                                                        let credit_array = this.state.vendor_credit_list;
                                                        let array = this.state.vendor_bill_list;
                                                        if(this.state.selected_vendor_credit_index >=0 && credit_array[this.state.selected_vendor_credit_index]) {
                                                        array.forEach((arr,ind)=>{
                                                            let applied_invoices = credit_array[this.state.selected_vendor_credit_index]["applied_invoices"] ? credit_array[this.state.selected_vendor_credit_index]["applied_invoices"]:[];
                                                            let idx = _.findIndex(applied_invoices, function(o) { return o.invoice_id == arr.id; });
                                                            if(idx >= 0) {
                                                                let amt = arr.applied_amount ? Number(arr.applied_amount):0;
                                                                let open_balance_after_apply = arr.open_balance_foreign_currency + amt;
                                                                array[ind] = { ...array[ind], open_balance_foreign_currency: open_balance_after_apply,applied_amount:''};
                                                            }
                                                        });
                                                        this.setState({ vendor_bill_list: array },()=>{
                                                            let available_credits = credit_array[this.state.selected_vendor_credit_index]["available_credits"];
                                                           credit_array[this.state.selected_vendor_credit_index] = { ...credit_array[this.state.selected_vendor_credit_index], applied_invoices:[],balance_after_used_amount:available_credits};
                                                        });
                                                                                       }

                                                    }}>Unapply</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Main Content Ends here */}
                            <div className="invoice-form">
                                <div className="pf-btm-wrap xs-pad-all">
                                    <div className="col-md-12 col-xs-12 text-right pad-no">
                                        <button className="btn btn-lightgray mar-rgt-5" onClick={()=>{
                                            this.clearAll();
                                        }}>Clear</button>
                                        <button className="btn btn-green" onClick={()=> this.saveTransactions()}>Save Transactions</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* MainContent Wrapper Ends here */}
                    </div>
                </div>
                {/* Main Wrapper Ends here */}
                {/* footer Starts here */}
                <footer className="container-fluid">
                    <p>© Copyrights 2019, Genie. All Rights Reserved.</p>
                </footer>
                {/* footer Ends here */}
            </div>

        );
    }
}
export default ClientDataReview;
