import React from 'react'
import LeftSidebar from '../left_sidebar'
import Footer from '../footer'

import Topbar from '../topbar'

import FetchAllApi from '../../api_links/fetch_all_api';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import jsPDF from "jspdf";
import "jspdf-autotable";
import html2canvas from 'html2canvas';
// import axios from 'axios';

// import { PDFtoIMG } from "react-pdf-to-image"
// import ReactToExcel from 'react-html-table-to-excel';

import jQuery from 'jquery'
import { batch } from 'react-redux';
import XLSX from 'xlsx';

// import 'bootstrap';
// import 'bootstrap-select';

class batchlist extends React.Component {
  constructor(props) {
    super(props)
    //const { history } = this.props;
    this.state = {
      logged_user_id: localStorage.getItem('logged_user_id'),
      logged_client_id: localStorage.getItem('logged_client_id'),
      logged_role_id: localStorage.getItem('logged_role_id'),
      logged_user_name: localStorage.getItem('logged_user_name'),
      logged_user_email: localStorage.getItem('logged_user_email'),
      logged_user_phone: localStorage.getItem('logged_user_phone'),
      logged_user_image: localStorage.getItem('logged_user_image'),
      logged_company_name: localStorage.getItem('logged_company_name'),
      role_permissions: JSON.parse(localStorage.getItem('role_permissions')) || [],
      dropdown: '',
      inbox_list: [],
      response_stus: 0,
      response_msg: 'No data found',
      item_details: '',
      item_file_path: '',
      batchListArray: [],
      TotalPages: [],
      pgNo: '10',
      selected_filter_id: 1,
      totalPagesCount: '',
      selectedLimit: 10,
      pagecountstart:0,
      pagecountend:0,
      ownPageLimit: '',
      searchtext:'',

    
      totalrecordcount:0,
      pageNumber:1,
    }
  }

  UNSAFE_componentWillMount() {
    jQuery(document.body).removeClass('minimize_leftbar')
    //console.log("logged_user_id", this.state.logged_user_id);

    jQuery('title').html('Batch List | GBSC')

    if (
      this.state.logged_user_id === '' ||
      this.state.logged_user_id === null ||
      this.state.logged_user_id === undefined
    ) {
      this.props.history.push('/')
    }

    // this.get_inbox_list()
  }

  pageLimitDefiner = () => {
    let result = [10, 20, 30, 40, 50]

    const pageLimit = result.map((item, i) => {
      return (
        <React.Fragment>
          <li key={i}
            onClick={() => {
              this.setState({ selectedLimit: item }, () => {
                this.getSpecificPage('', item)
              })
            }}
          >
            <a href='javascript:;'>{item}</a>
          </li>
        </React.Fragment>
      )
    })

    this.setState({ ownPageLimit: pageLimit })

  }


  routedChange(parameter) {
    this.props.history.push('/' + parameter)
    window.scrollTo(0, 0)
  }
  componentWillUnmount() { }

  componentDidMount() {
    // this.pageLimitDefiner()
    this.getBatchlist()

    //jQuery(".select-picker").selectpicker();

    require('jquery-mousewheel')
    require('malihu-custom-scrollbar-plugin')

    jQuery('.item-listwrap').mCustomScrollbar({
      scrollEasing: 'linear',
      scrollInertia: 600,
      scrollbarPosition: 'outside'
    })

    jQuery('.label-enclose .label span').click(function () {
      //jQuery('.label-enclose .label').removeClass('active')
      jQuery(this)
        .parent('.label-enclose .label')
        .addClass('active')
    })
    jQuery('.label-enclose .label a').click(function () {
      jQuery(this)
        .parent('.label-enclose .label')
        .removeClass('active')
    })
  }

  printDocument() {
    const input = document.getElementById("table-to-xls");
    // html2canvas(input)
    //   .then((canvas) => {
    //     var imgWidth = 200;
    //     var pageHeight = 290;
    //     var imgHeight = canvas.height * imgWidth / canvas.width;
    //     var heightLeft = imgHeight;
    //     const imgData = canvas.toDataURL('image/png');
    //     const pdf = new jsPDF('p', 'mm', 'a4')
    //     var position = 0;
    //     var heightLeft = imgHeight;
    //     pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
    //     pdf.save("download.pdf");
    //   });

    var doc = new jsPDF('p', 'in', 'letter');
      var tTB = document.getElementById("table-to-xls");
      var atTB = doc.autoTableHtmlToJson(tTB, true);
      var cols = atTB.columns;
      var data = atTB.rows;
      let new_rows = [];
      data.forEach((row,index)=>{
      let row_data = row;
      row_data.splice(6,1); 
      new_rows.push(row_data);
      });

        //here you are going to set which column you will truncate. Moreover, .splice(index number of the column(your start), the number of columns you will exclude)
        cols.splice(6,1); 
        doc.text("Batch List", 3, 0.5);
        doc.autoTable({
                  startY: 0.6,
                  head: [cols],
                  body:new_rows
        });
     doc.save('Batch-List.pdf');
  }

  exportFunc = (batchListArray) => {
     let customer_data = [];
     batchListArray.forEach((cus,i)=>{
       let obj = {
         "Batch Number":cus.batch_number,
         "Account Name":cus.account_name,
         "Trans Type":cus.transaction_type==1?"Sales Invoice and Credit Memo":
         cus.transaction_type==2?"Bill and Bill Credits":
         cus.transaction_type==3?"Cheque":
         cus.transaction_type==4?"Deposit":'',
         "Currency":cus.currency,
         "Created Date":cus.created_date,
         "Status":cus.status_text
       };
       customer_data.push(obj);
     });
   
   var ws = XLSX.utils.json_to_sheet(customer_data);
   ws['!cols'] = [{ width: 15 }, { width: 15 }, { width: 15 }, { width: 15 }];
   var wb = XLSX.utils.book_new();
   XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
   XLSX.writeFile(wb, "Batch-List.xlsx");
 
   };

  componentDidUpdate() {
    window.jQuery(".selectpicker").selectpicker("refresh");
  };


  getBatchlist = async (pageNumber) => {
    let client_id = this.state.logged_client_id
    let page = pageNumber || 1 
    let limit = this.state.selectedLimit
    this.setState({pageNumber: Number(pageNumber)})

    FetchAllApi.get_batch_transaction_list(
      page,
      limit,
      client_id,
      (err, response) => {
        console.log('Customer_dlist', response)
        if (response.status === 1) {
          // let batchlist = response.details
          //  let totalPagesCount = new Array(parseInt( response.TotalPages))
          // var totalPagesCount = []
          // for (var i = 1; i <= response.total_page; i++) {
          //   totalPagesCount.push(i)
          // }let totalPagesCount = [];
          let totalPagesCount = [];
          for(let i = 1; i <= response.TotalPages; i++) {
            totalPagesCount.push(i);
          }
          this.setState({ batchListArray: response.details, 
            totalrecordcount: response.total_item_count,
            TotalPages:totalPagesCount,
            pagecountstart: page===1?1:((limit*(page-1))+1),
            pagecountend: limit*page })
          // this.setState({
          //   batchListArray: batchlist,
          //   // TotalPages: totalPagesCount,
          //   // selectedLimit: this.state.selectedLimit,
          //   // totalPagesCount: response.TotalPages
          // })
        } else {
          this.setState({
            batchListArray: [],
            totalrecordcount: "",
            TotalPages:"",
            pagecountstart: 0,
            pagecountend:0
          })
        }
      }
    )
    jQuery('a[href="#Estimate"]').tab('show');
    jQuery(' li >').parent().removeClass('active');
    jQuery('.nav nav-pills > li >').parent().removeClass('active');
    jQuery(' li > a[href="#Estimate"]').parent().addClass('active');
    jQuery('.nav nav-pills > li > a[href="#Estimate"]').parent().addClass('active'); 
  
    // let pagenumber=pageNumber===''?1:pageNumber;
    // this.setState({pagecountstart: pagenumber===1?1:this.state.pagecountend+1,pagecountend:limit*pagenumber});
  }

  logoutLink() {
    localStorage.clear();
    this.props.history.push('/')
  }

  voidBatchTransaction(batch_id){
    let client_id = this.state.logged_client_id;
    FetchAllApi.voidBatchEntries({client_id:client_id,batch_transaction_id:batch_id}, (err, response) => {
      if (response.status === 1) {

        alert(response.message);
        this.getBatchlist()
      } else {
        alert(response.message);
      }
    });
  }

 render() {       
console.log(this.state.batchListArray)
    return (
      <div>
        <div className='container-fluid'>
          <div className='row'>
            <LeftSidebar history={this.props.history} pageSubmit={e => this.pageLink(e)} />

            <div className='main-wrap col-md-12 col-xs-12 pad-r-no'>
              <div className='top-bar col-md-12 col-xs-12 pad-r-no'>
                <div className='nav-brand-res visible-xs'>
                  <img
                    className='img-responsive'
                    src='../images/logo-icon.png'
                    alt='LogoIcon'
                  />
                </div>
                <a href='javascript:;' class='back hidden-xs' onClick={() => this.props.history.goBack()}>
                  <img src='../images/back-arrow-blue.svg' />
                </a>
                {/* <span className='page-title hidden-xs'>Inbox</span> */}
                <ul className='list-unstyled breadcrumb page-title hidden-xs'>
                  <li>
                    <a
                      href='javascript: ;'
                      onClick={this.routedChange.bind(this, 'employee-list')}
                    >
                      Batch Transaction
                    </a>
                  </li>
                  <li>Batch List</li>
                </ul>
                <Topbar history={this.props.history} logoutSubmit={e => this.logoutLink()} />
              </div>
              <div className='content-top col-md-12 col-xs-12'>
                <h4 className='fw-sbold mar-t-no'>Batch List</h4>
                <div className='custom-form form-inline col-md-12 col-xs-12 h-small pad-top'>
                  <div className='row'>
                   
                    <div className='btn-group'>                    

                      <div className='dropdown menu-item new-cus pull-right'>
                        <button
                          className='btn btn-green dropdown-toggle btn-arrow'
                          data-toggle='dropdown'
                          aria-expanded='false'
                        >
                          Export
                          <span className='caret' />
                        </button>
                        <ul className='dropdown-menu align-right'>
                          <li>
                            <a href="javascript:;" onClick={this.printDocument}>

                              Export as PDF</a>
                          </li>
                          <li>
                              {/* <ReactHTMLTableToExcel
                            className="excel-button"
                              table="table-to-xls"
                              filename="Batch-List"
                              sheet="Sheet1"
                              buttonText="Export as Excel"
                            /> */}

                          <a href='javascript:;' 
                            id='customXLSButton' 
                            onClick={()=>{this.exportFunc(this.state.batchListArray)}} >
                            Export as Excel
                          </a>
                            
                          </li>
                        </ul>
                      {/* </button> */}
                      </div>
                    </div>
                   
                   
                    <button
                      className='btn btn-blue add-new pull-right'
                      onClick={() => {
                        localStorage.setItem('batchid', 0)
                        this.props.history.push('/enter_batch_transaction')
                      }}
                    >
                      <img
                        className='filter-white'
                        src='../images/plus-add.svg'
                        alt='icon'
                      />
                      Add New Batch Transaction
                    </button>
                  </div>
                </div>
              </div>

              <div className='main-content col-md-12 col-xs-12'>
                <div className='content-sec cus-list col-md-12 col-xs-12 pad-no mar-t-no'>
                <div className='show-per-page pull-right'>
                        <label>Show per page</label>
                        <div className='custom-select-drop dropdown'>
                          <a
                            aria-expanded='false'
                            aria-haspopup='true'
                            role='button'
                            data-toggle='dropdown'
                            className='dropdown-toggle btn form-control'
                            href='javascript:;'
                          >
                            <span id='selected'> </span> {this.state.selectedLimit}
                            <span className='caret' />
                          </a>
                          <ul className='dropdown-menu align-right minw-unset'>
                            {/* <li className="active"><a href="javascript:;">1</a></li> */}
                            <li onClick={() => { this.setState({ selectedLimit: 10 }, () => { this.getBatchlist(1) }) }}>
                              <a href='javascript:;'>10</a>
                            </li>
                            <li onClick={() => { this.setState({ selectedLimit: 20 }, () => { this.getBatchlist(1) }) }}>
                              <a href='javascript:;'>20</a>
                            </li>
                            <li onClick={() => { this.setState({ selectedLimit: 30 }, () => { this.getBatchlist(1) }) }}>
                              <a href='javascript:;'>30</a>
                            </li>
                            <li onClick={() => { this.setState({ selectedLimit: 40 }, () => { this.getBatchlist(1) }) }}>
                              <a href='javascript:;'>40</a>
                            </li>
                            <li onClick={() => { this.setState({ selectedLimit: 50 }, () => { this.getBatchlist(1) }) }}>
                              <a href='javascript:;'>50</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                  <div className='list-table col-md-12 col-xs-12 pad-no'>
                    <div
                      className='cus-table-responsive'
                      style={{ paddingRight: '1px' }}
                    >
                      <table className='table' id ='table-to-xls'>
                        <thead>
                          <tr>                            
                            <th>Batch Number</th>
                            <th>Account Name</th>
                            <th>Trans Type</th>
                            <th>Currency</th>
                            <th>Created Date</th>
                            <th>Status</th>
                            <th className='text-right action-td' />
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.batchListArray &&
                            this.state.batchListArray.map((item, i) => {
                              return (                               
                                <tr onClick={() => {
                                  localStorage.setItem('batchid', item.id)
                                  this.props.history.push(
                                    '/enter_batch_transaction'                                    )
                                }
                                }>                                  
                                  <td> 
                                   <span className='fw-med'>
                                      {item.batch_number ? item.batch_number:item.id}
                                    </span>                                   
                                  </td>
                                  <td>
                                    <span className='fw-med'>
                                      {item.account_name}
                                    </span>
                                  </td>

                                  <td className='cont-detail'>
                                    <span>
                                      {item.transaction_type==1?"Sales Invoice and Credit Memo":
                                      item.transaction_type==2?"Bill and Bill Credits":
                                      item.transaction_type==3?"Cheque":
                                      item.transaction_type==4?"Deposit":''}
                                    </span>
                                    
                                  </td>

                                  <td className='cont-detail'>                                   
                                    
                                    <span>
                                    
                                        {item.currency}
                                     
                                    </span>
                                  </td>
                                  <td className='cont-detail'>                                   
                                    
                                    <span>
                                    
                                        {item.created_date}
                                     
                                    </span>
                                  </td>

                                {(() => {
                                      if (item.status_text == "Posted") {
                                        return (
                                          <td><span className="badge green dib">{item.status_text}</span></td>
                                        )
                                      } else if (item.status_text == "Voided") {
                                        return (
                                          <td><span className="badge red dib">{item.status_text}</span></td>
                                        )
                                      } else {
                                        return (
                                          <td><span className="badge red dib"></span></td>
                                        )
                                      }
                                    })()}

                                  <td onClick={e => e.stopPropagation()} className='text-right action-td'>
                                  <div className="dropdown menu-item new-cus">
                                      <a
                                        href="javascript"
                                        class="dropdown-toggle"
                                        data-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <button
                                          className="btn btn-green dropdown-toggle"
                                          type='button'

                                        >
                                          Action
                                        <span className='caret' />
                                        </button>
                                      </a>
                                      <ul className="dropdown-menu align-right">

                                        <li>
                                          <a
                                            onClick={() => {
                                              localStorage.setItem('batchid', item.id)
                                              localStorage.setItem('hasId', item.id)
                                              this.props.history.push(
                                                '/enter_batch_transaction'                                    
                                                )
                                              }}
                                          >Edit</a>
                                        </li>
                                        <li onClick={() => {
                                           this.voidBatchTransaction(item.id)
                                        }}>
                                          <a href='javascript:;'>Void</a>
                                        </li>
                                      </ul>
                                    </div>

                                  </td>
                                </tr>
                              )
                            })}
                        </tbody>
                      </table>
                      <div className='col-md-12 col-xs-12 pad-no'>
                      {this.state.TotalPages && this.state.TotalPages.length > 0 &&
                        <p className='fw-med pull-left'>
                          Showing {this.state.pagecountstart} - {(this.state.pagecountend > this.state.totalrecordcount)?this.state.totalrecordcount:this.state.pagecountend} of {this.state.totalrecordcount} items
                        </p>
                      }

                      <div className='pull-right pagination-wrap'>
                        <ul className='pagination'>
                          {this.state.TotalPages &&
                            this.state.TotalPages.map((item, i) => {
                              return (
                                <>
                                  <li
                                    key={i}
                                    onClick={() => this.getBatchlist(i + 1)}

                                  >
                                    <a 
                                    href='javascript:;' 
                                     style={
                                    this.state.pageNumber === (i+1)
                                          ? {
                                            background: "#2491D9",
                                            marginLeft: "5px",
                                            color: "#fff",
                                          }
                                          : {}
                                      }>{i + 1}</a>
                                  </li>

                                </>
                              )
                            })}
                        </ul>
                      </div>
                    </div>
                    </div>                   
                  </div>
                </div>
              </div>
              <div className='main-wrap col-md-12 col-xs-12 pad-r-no'></div>
            </div>
          </div>

          <Footer logoutSubmit={e => this.logoutLink()} />
        </div>
      </div>
    )
  }
}
export default batchlist
